import Rails from '@rails/ujs'
import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

Rails.start()

$(document).ready(() => {
  var filter_timer;
  var controller = $('#controller').data('name')
  $('input#filter').on('keyup', function() {
    clearTimeout(filter_timer)
    
    filter_timer = setTimeout(function() {
      $('#spinner').css('visibility', 'visible')
      var val = $('input#filter').val()
      if (!val) {
        $('#spinner').css('visibility', 'hidden')
        return
      }
      $('#datatable').load('/'+controller+'/filter', {ajaxfilter: val})
      $('#spinner').css('visibility', 'hidden')
    }, 500)
  })
  $('input#filter').on('keydown', function() {
    clearTimeout(filter_timer)
  })
})
